import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import { mutations } from "@/utils/store.js";

export default {
  methods: {
    getSurveyTotalPrice() {
      const GetSurveyPrice = new Promise((resolve) => {
        wsUtils.GetSurveyPrice(jsUtils.getSurveyPrice(), resolve);
      });
      GetSurveyPrice.then((data) => {
        const totalPrice = data
          .map((item) => item.total)
          .reduce((prev, curr) => prev + curr, 0);
        mutations.setSurveyTotalPrice(totalPrice);
      });
    },
    getPackTestTotalPrice() {
      let user = jsUtils.getUserInfo();
      const randomIndetifier = Math.random();
      mutations.setPriceLoader(randomIndetifier);
      const GetPackTestPrice = new Promise((resolve) => {
        wsUtils.GetPackTestPrice(jsUtils.getPackTestPrice(), resolve);
      });
      GetPackTestPrice.then((data) => {
        mutations.setPackTestTotalPrice(data.total);
        setTimeout(() => {
          mutations.setPriceLoader(randomIndetifier);
        }, 1000);
        
        if (user.SkinIdentifier === "pg") {
          this.getPackFeasibility(randomIndetifier);
        }
      });
    },
    getMapMakerTotalPrice() {
      const randomIndetifier = Math.random();
      mutations.setPriceLoader(randomIndetifier);
      const data = {
        wsName: "GetMapMakerSurveyPrice",
        data: {
          surveyID: this.$route.params.id,
        },
      };
      const GetMapMakerPrice = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });
      GetMapMakerPrice.then((data) => {
        mutations.setPackTestTotalPrice(data.price);
        setTimeout(() => {
          mutations.setPriceLoader(randomIndetifier);
        }, 1000);
      });
    },
    getPackFeasibility(randomIndetifier) {
      mutations.setCheckingFeasibilityLoader(randomIndetifier);
      const GetPackFeasibility = new Promise((resolve) => {
        wsUtils.GetPackFeasibility(jsUtils.getPackTestPrice(), resolve);
      });
      GetPackFeasibility.then((data) => {
        if (data.success) {
          mutations.setFeasibilitySuccess(true);
          jsUtils.setFeasibilitySuccess(true);
        } else {
          mutations.setFeasibilitySuccess(false);
          jsUtils.setFeasibilitySuccess(false);
        }
        mutations.setCheckingFeasibilityLoader(randomIndetifier);
      });
    },
    getBrandFingerprintPrice() {
      const randomIndetifier = Math.random();
      mutations.setPriceLoader(randomIndetifier);
      const data = {
        wsName: "GetBrandFingerprintSurveyPrice",
        data: {
          surveyID: this.$route.params.id,
        },
      };
      const GetBrandFingerprintSurveyPrice = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });
      GetBrandFingerprintSurveyPrice.then((data) => {

        mutations.setPackTestTotalPrice(data.total_price);
        mutations.setAllPriceDetails(data);
        setTimeout(() => {
          mutations.setPriceLoader(randomIndetifier);
        }, 1000);
      });
    },
  },
};
